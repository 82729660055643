exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-cfp-js": () => import("./../../../src/pages/cfp.js" /* webpackChunkName: "component---src-pages-cfp-js" */),
  "component---src-pages-coc-js": () => import("./../../../src/pages/coc.js" /* webpackChunkName: "component---src-pages-coc-js" */),
  "component---src-pages-combo-tickets-js": () => import("./../../../src/pages/combo-tickets.js" /* webpackChunkName: "component---src-pages-combo-tickets-js" */),
  "component---src-pages-covid-js": () => import("./../../../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-diversity-js": () => import("./../../../src/pages/diversity.js" /* webpackChunkName: "component---src-pages-diversity-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-hotel-js": () => import("./../../../src/pages/hotel.js" /* webpackChunkName: "component---src-pages-hotel-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-program-index-js": () => import("./../../../src/pages/program/index.js" /* webpackChunkName: "component---src-pages-program-index-js" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-speakers-index-js": () => import("./../../../src/pages/speakers/index.js" /* webpackChunkName: "component---src-pages-speakers-index-js" */),
  "component---src-pages-sponsor-us-js": () => import("./../../../src/pages/sponsor-us.js" /* webpackChunkName: "component---src-pages-sponsor-us-js" */),
  "component---src-pages-sustainability-js": () => import("./../../../src/pages/sustainability.js" /* webpackChunkName: "component---src-pages-sustainability-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-travel-js": () => import("./../../../src/pages/travel.js" /* webpackChunkName: "component---src-pages-travel-js" */),
  "component---src-pages-values-js": () => import("./../../../src/pages/values.js" /* webpackChunkName: "component---src-pages-values-js" */),
  "component---src-templates-blog-js-content-file-path-content-blog-20240926-a-conversation-with-data-mesh-live-curator-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/blog/20240926-A conversation with Data Mesh Live curator.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-20240926-a-conversation-with-data-mesh-live-curator-mdx" */),
  "component---src-templates-blog-js-content-file-path-content-blog-a-data-mesh-conference-in-this-economy-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/blog/a-data-mesh-conference-in-this-economy.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-a-data-mesh-conference-in-this-economy-mdx" */),
  "component---src-templates-blog-js-content-file-path-content-blog-data-products-at-data-mesh-live-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/blog/data-products-at-data-mesh-live.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-data-products-at-data-mesh-live-mdx" */),
  "component---src-templates-blog-js-content-file-path-content-blog-pre-conference-workshops-andrew-jones-jacek-majchrzak-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/blog/pre-conference-workshops-andrew-jones-jacek-majchrzak.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-pre-conference-workshops-andrew-jones-jacek-majchrzak-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-a-defence-of-technical-excellence-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/a-defence-of-technical-excellence.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-a-defence-of-technical-excellence-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-a-hands-on-introduction-to-domain-driven-design-and-hexagonal-architecture-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/a-hands-on-introduction-to-domain-driven-design-and-hexagonal-architecture.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-a-hands-on-introduction-to-domain-driven-design-and-hexagonal-architecture-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-accelerating-integrations-at-wix-with-semantic-messaging-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/accelerating-integrations-at-wix-with-semantic-messaging.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-accelerating-integrations-at-wix-with-semantic-messaging-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-advanced-refactor-using-ddd-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/advanced-refactor-using-ddd.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-advanced-refactor-using-ddd-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-advanced-software-architecture-with-residuality-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/advanced-software-architecture-with-residuality.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-advanced-software-architecture-with-residuality-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-ai-accelerated-legacy-modernisation-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/ai-accelerated-legacy-modernisation.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-ai-accelerated-legacy-modernisation-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-architecture-as-code-making-architecture-work-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/architecture-as-code-making-architecture-work.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-architecture-as-code-making-architecture-work-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-architecture-the-hard-parts-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/architecture-the-hard-parts.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-architecture-the-hard-parts-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-automating-business-processes-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/automating-business-processes.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-automating-business-processes-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-automating-federated-data-governance-with-sharer-driven-data-contracts-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/automating-federated-data-governance-with-sharer-driven-data-contracts.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-automating-federated-data-governance-with-sharer-driven-data-contracts-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-baking-domain-concepts-into-code-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/baking-domain-concepts-into-code.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-baking-domain-concepts-into-code-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-becoming-a-great-team-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/becoming-a-great-team.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-becoming-a-great-team-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-beyond-words-navigating-the-power-and-pitfalls-of-metaphors-in-software-design-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/beyond-words-navigating-the-power-and-pitfalls-of-metaphors-in-software-design.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-beyond-words-navigating-the-power-and-pitfalls-of-metaphors-in-software-design-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-breaking-conventions-for-more-performant-models-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/breaking-conventions-for-more-performant-models.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-breaking-conventions-for-more-performant-models-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-bringing-clarity-to-event-streams-enabling-analytics-and-ai-through-rich-metadata-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/bringing-clarity-to-event-streams-enabling-analytics-and-ai-through-rich-metadata.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-bringing-clarity-to-event-streams-enabling-analytics-and-ai-through-rich-metadata-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-charting-the-uncharted-discovering-and-evolving-bounded-contexts-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/charting-the-uncharted-discovering-and-evolving-bounded-contexts.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-charting-the-uncharted-discovering-and-evolving-bounded-contexts-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-cqrs-and-event-sourcing-in-the-age-of-durable-execution-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/cqrs-and-event-sourcing-in-the-age-of-durable-execution.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-cqrs-and-event-sourcing-in-the-age-of-durable-execution-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-creating-a-language-for-collaborative-modelling-hands-on-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/creating-a-language-for-collaborative-modelling-hands-on.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-creating-a-language-for-collaborative-modelling-hands-on-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-data-contract-workshop-using-open-source-tooling-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/data-contract-workshop-using-open-source-tooling.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-data-contract-workshop-using-open-source-tooling-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-data-mesh-grand-challenges-closing-the-gap-between-concept-implementation-and-business-impact-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/data-mesh-grand-challenges-closing-the-gap-between-concept-implementation-and-business-impact.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-data-mesh-grand-challenges-closing-the-gap-between-concept-implementation-and-business-impact-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-data-mesh-in-action-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/data-mesh-in-action.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-data-mesh-in-action-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-ddd-and-bdd-then-and-now-unpacking-similarities-and-differences-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/ddd-and-bdd-then-and-now-unpacking-similarities-and-differences.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-ddd-and-bdd-then-and-now-unpacking-similarities-and-differences-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-ddd-for-mergers-and-acquisitions-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/ddd-for-mergers-and-acquisitions.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-ddd-for-mergers-and-acquisitions-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-ddd-surrounded-by-legacy-govt-edition-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/ddd-surrounded-by-legacy-govt-edition.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-ddd-surrounded-by-legacy-govt-edition-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-design-a-system-in-2-hours-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/design-a-system-in-2-hours.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-design-a-system-in-2-hours-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-design-patterns-for-software-diagramming-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/design-patterns-for-software-diagramming.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-design-patterns-for-software-diagramming-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-designing-constraints-enabling-emergence-in-complex-sociotechnical-systems-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/designing-constraints-enabling-emergence-in-complex-sociotechnical-systems.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-designing-constraints-enabling-emergence-in-complex-sociotechnical-systems-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-designing-microservices-responsibilities-apis-and-collaborations-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/designing-microservices-responsibilities-apis-and-collaborations.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-designing-microservices-responsibilities-apis-and-collaborations-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-digging-deep-into-a-domain-by-digging-scientific-papers-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/digging-deep-into-a-domain-by-digging-scientific-papers.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-digging-deep-into-a-domain-by-digging-scientific-papers-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-domain-driven-redesign-rejuvenating-a-forty-year-old-legacy-system-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/domain-driven-redesign-rejuvenating-a-forty-year-old-legacy-system.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-domain-driven-redesign-rejuvenating-a-forty-year-old-legacy-system-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-domain-experiments-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/domain-experiments.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-domain-experiments-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-driving-business-engagement-through-domain-ownership-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/driving-business-engagement-through-domain-ownership.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-driving-business-engagement-through-domain-ownership-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-elevating-tactical-ddd-patterns-through-object-calisthenics-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/elevating-tactical-ddd-patterns-through-object-calisthenics.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-elevating-tactical-ddd-patterns-through-object-calisthenics-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-eliminate-the-complexities-of-event-driven-architecture-with-domain-driven-design-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/eliminate-the-complexities-of-event-driven-architecture-with-domain-driven-design.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-eliminate-the-complexities-of-event-driven-architecture-with-domain-driven-design-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-enable-clean-domain-models-and-manage-complexity-with-hexagonal-architecture-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/enable-clean-domain-models-and-manage-complexity-with-hexagonal-architecture.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-enable-clean-domain-models-and-manage-complexity-with-hexagonal-architecture-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-evaluating-patterns-in-gen-ai-outputs-alternative-what-can-we-expect-of-llms-as-software-engs-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/evaluating-patterns-in-gen-ai-outputs-alternative-what-can-we-expect-of-llms-as-software-engs.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-evaluating-patterns-in-gen-ai-outputs-alternative-what-can-we-expect-of-llms-as-software-engs-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-event-driven-modelling-anti-patterns-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/event-driven-modelling-anti-patterns.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-event-driven-modelling-anti-patterns-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-event-sourcing-the-backbone-of-agentic-ai-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/event-sourcing-the-backbone-of-agentic-ai.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-event-sourcing-the-backbone-of-agentic-ai-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-event-sourcing-the-what-why-and-how-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/event-sourcing-the-what-why-and-how.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-event-sourcing-the-what-why-and-how-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-eventstorming-masterclass-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/eventstorming-masterclass.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-eventstorming-masterclass-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-expanding-your-design-heuristic-repertoire-one-experiment-at-a-time-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/expanding-your-design-heuristic-repertoire-one-experiment-at-a-time.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-expanding-your-design-heuristic-repertoire-one-experiment-at-a-time-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-fast-feedback-development-by-avoiding-the-fallacy-of-integrated-end-to-end-tests-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/fast-feedback-development-by-avoiding-the-fallacy-of-integrated-end-to-end-tests.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-fast-feedback-development-by-avoiding-the-fallacy-of-integrated-end-to-end-tests-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-federated-computational-governance-with-ai-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/federated-computational-governance-with-ai.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-federated-computational-governance-with-ai-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-from-business-goals-to-software-requirements-combining-impact-mapping-and-domain-storytelling-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/from-business-goals-to-software-requirements-combining-impact-mapping-and-domain-storytelling.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-from-business-goals-to-software-requirements-combining-impact-mapping-and-domain-storytelling-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-from-kitchen-to-catalog-applying-the-chefs-discipline-to-data-products-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/from-kitchen-to-catalog-applying-the-chefs-discipline-to-data-products.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-from-kitchen-to-catalog-applying-the-chefs-discipline-to-data-products-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-getting-your-kafka-costs-under-control-a-quest-for-more-transparency-and-accountability-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/getting-your-kafka-costs-under-control-a-quest-for-more-transparency-and-accountability.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-getting-your-kafka-costs-under-control-a-quest-for-more-transparency-and-accountability-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-growing-and-thriving-in-a-multi-model-world-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/growing-and-thriving-in-a-multi-model-world.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-growing-and-thriving-in-a-multi-model-world-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-harnessing-the-durable-execution-pattern-from-failures-to-fault-tolerance-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/harnessing-the-durable-execution-pattern-from-failures-to-fault-tolerance.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-harnessing-the-durable-execution-pattern-from-failures-to-fault-tolerance-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-helping-stakeholders-make-investment-decisions-with-collaborative-modeling-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/helping-stakeholders-make-investment-decisions-with-collaborative-modeling.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-helping-stakeholders-make-investment-decisions-with-collaborative-modeling-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-how-llms-work-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/how-llms-work.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-how-llms-work-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-how-to-design-an-api-using-ddd-and-ai-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/how-to-design-an-api-using-ddd-and-ai.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-how-to-design-an-api-using-ddd-and-ai-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-how-to-make-your-stakeholders-talk-techniques-from-professional-interviewers-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/how-to-make-your-stakeholders-talk-techniques-from-professional-interviewers.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-how-to-make-your-stakeholders-talk-techniques-from-professional-interviewers-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-how-to-train-your-domain-model-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/how-to-train-your-domain-model.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-how-to-train-your-domain-model-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-impact-mapping-workshop-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/impact-mapping-workshop.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-impact-mapping-workshop-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-implementing-a-data-mesh-with-data-contracts-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/implementing-a-data-mesh-with-data-contracts.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-implementing-a-data-mesh-with-data-contracts-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-implementing-tactical-patterns-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/implementing-tactical-patterns.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-implementing-tactical-patterns-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-improving-feature-flag-implementation-to-achieve-flow-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/improving-feature-flag-implementation-to-achieve-flow.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-improving-feature-flag-implementation-to-achieve-flow-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-information-architecture-recommoning-how-standardization-enables-differentiation-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/information-architecture-recommoning-how-standardization-enables-differentiation.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-information-architecture-recommoning-how-standardization-enables-differentiation-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-interactively-designing-distributed-systems-integration-with-collaborative-modelling-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/interactively-designing-distributed-systems-integration-with-collaborative-modelling.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-interactively-designing-distributed-systems-integration-with-collaborative-modelling-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-leadership-in-software-design-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/leadership-in-software-design.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-leadership-in-software-design-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-limitation-driven-exploration-hands-on-modelling-workshop-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/limitation-driven-exploration-hands-on-modelling-workshop.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-limitation-driven-exploration-hands-on-modelling-workshop-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-living-domain-model-continuous-refactoring-to-accelerate-delivery-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/living-domain-model-continuous-refactoring-to-accelerate-delivery.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-living-domain-model-continuous-refactoring-to-accelerate-delivery-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-model-drift-and-software-attractors-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/model-drift-and-software-attractors.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-model-drift-and-software-attractors-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-model-tension-heuristics-preventing-accidental-design-debt-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/model-tension-heuristics-preventing-accidental-design-debt.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-model-tension-heuristics-preventing-accidental-design-debt-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-modelling-stories-of-the-beautiful-mess-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/modelling-stories-of-the-beautiful-mess.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-modelling-stories-of-the-beautiful-mess-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-modernising-in-healthcare-a-case-study-in-decision-making-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/modernising-in-healthcare-a-case-study-in-decision-making.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-modernising-in-healthcare-a-case-study-in-decision-making-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-modular-monoliths-the-architecture-that-scales-with-your-domain-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/modular-monoliths-the-architecture-that-scales-with-your-domain.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-modular-monoliths-the-architecture-that-scales-with-your-domain-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-orchestration-vs-choreography-the-good-the-bad-and-the-trade-offs-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/orchestration-vs-choreography-the-good-the-bad-and-the-trade-offs.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-orchestration-vs-choreography-the-good-the-bad-and-the-trade-offs-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-our-journey-to-an-event-sourcing-middleware-eurostar-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/our-journey-to-an-event-sourcing-middleware-eurostar.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-our-journey-to-an-event-sourcing-middleware-eurostar-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-persistence-strategies-for-aggregates-exploring-the-trade-offs-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/persistence-strategies-for-aggregates-exploring-the-trade-offs.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-persistence-strategies-for-aggregates-exploring-the-trade-offs-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-platforms-ddd-for-technical-domains-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/platforms-ddd-for-technical-domains.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-platforms-ddd-for-technical-domains-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-practical-introduction-to-event-sourcing-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/practical-introduction-to-event-sourcing.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-practical-introduction-to-event-sourcing-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-productizing-data-along-the-value-chain-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/productizing-data-along-the-value-chain.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-productizing-data-along-the-value-chain-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-put-an-ml-model-in-production-with-me-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/put-an-ml-model-in-production-with-me.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-put-an-ml-model-in-production-with-me-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-realizing-domain-design-through-architectural-modularity-and-service-granularity-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/realizing-domain-design-through-architectural-modularity-and-service-granularity.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-realizing-domain-design-through-architectural-modularity-and-service-granularity-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-residuality-qanda-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/residuality-qanda.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-residuality-qanda-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-see-the-forest-for-the-trees-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/see-the-forest-for-the-trees.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-see-the-forest-for-the-trees-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-situational-leadership-for-architecture-decision-making-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/situational-leadership-for-architecture-decision-making.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-situational-leadership-for-architecture-decision-making-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-so-you-want-to-maintain-a-reliable-event-driven-system-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/so-you-want-to-maintain-a-reliable-event-driven-system.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-so-you-want-to-maintain-a-reliable-event-driven-system-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-software-design-masterclass-with-eric-evans-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/software-design-masterclass-with-eric-evans.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-software-design-masterclass-with-eric-evans-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-strategic-domain-driven-design-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/strategic-domain-driven-design.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-strategic-domain-driven-design-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-system-design-as-system-design-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/system-design-as-system-design.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-system-design-as-system-design-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-system-seeing-and-system-design-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/system-seeing-and-system-design.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-system-seeing-and-system-design-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-team-topologies-and-the-microservice-architecture-a-synergistic-relationship-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/team-topologies-and-the-microservice-architecture-a-synergistic-relationship.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-team-topologies-and-the-microservice-architecture-a-synergistic-relationship-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-team-topologies-is-not-enough-you-need-a-management-model-to-match-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/team-topologies-is-not-enough-you-need-a-management-model-to-match.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-team-topologies-is-not-enough-you-need-a-management-model-to-match-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-the-anatomy-of-business-conditions-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/the-anatomy-of-business-conditions.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-the-anatomy-of-business-conditions-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-the-architect-elevator-visiting-the-upper-floors-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/the-architect-elevator-visiting-the-upper-floors.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-the-architect-elevator-visiting-the-upper-floors-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-the-boeing-737-max-when-humans-and-technology-dont-mix-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/the-boeing-737-max-when-humans-and-technology-dont-mix.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-the-boeing-737-max-when-humans-and-technology-dont-mix-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-the-c-4-model-misconceptions-misuses-and-mistakes-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/the-c4-model-misconceptions-misuses-and-mistakes.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-the-c-4-model-misconceptions-misuses-and-mistakes-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-the-invisible-governance-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/the-invisible-governance.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-the-invisible-governance-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-the-magic-of-small-things-microservices-10-years-on-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/the-magic-of-small-things-microservices-10-years-on.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-the-magic-of-small-things-microservices-10-years-on-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-the-unbearable-weight-of-architecture-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/the-unbearable-weight-of-architecture.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-the-unbearable-weight-of-architecture-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-through-the-looking-glass-applying-architecture-principles-to-social-systems-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/through-the-looking-glass-applying-architecture-principles-to-social-systems.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-through-the-looking-glass-applying-architecture-principles-to-social-systems-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-undo-so-you-want-to-rewrite-history-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/undo-so-you-want-to-rewrite-history.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-undo-so-you-want-to-rewrite-history-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-unifying-databases-and-streaming-systems-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/unifying-databases-and-streaming-systems.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-unifying-databases-and-streaming-systems-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-use-ddd-for-enterprise-application-integration-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/use-ddd-for-enterprise-application-integration.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-use-ddd-for-enterprise-application-integration-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-variability-the-second-hardest-problem-in-systems-architecture-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/variability-the-second-hardest-problem-in-systems-architecture.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-variability-the-second-hardest-problem-in-systems-architecture-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-versioning-event-driven-systems-change-is-inevitable-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/versioning-event-driven-systems-change-is-inevitable.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-versioning-event-driven-systems-change-is-inevitable-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-visualising-software-architecture-with-the-c-4-model-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/visualising-software-architecture-with-the-c4-model.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-visualising-software-architecture-with-the-c-4-model-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-what-we-talk-about-when-we-talk-about-data-products-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/what-we-talk-about-when-we-talk-about-data-products.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-what-we-talk-about-when-we-talk-about-data-products-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-why-data-contracts-are-the-foundations-of-a-data-mesh-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/why-data-contracts-are-the-foundations-of-a-data-mesh.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-why-data-contracts-are-the-foundations-of-a-data-mesh-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-alberto-acerbis-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/alberto-acerbis.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-alberto-acerbis-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-alberto-brandolini-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/alberto-brandolini.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-alberto-brandolini-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-alessandro-colla-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/alessandro-colla.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-alessandro-colla-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-alexander-rovner-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/alexander-rovner.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-alexander-rovner-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-andrea-gioia-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/andrea-gioia.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-andrea-gioia-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-andrew-harmel-law-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/andrew-harmel-law.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-andrew-harmel-law-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-andrew-jones-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/andrew-jones.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-andrew-jones-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-anita-kvamme-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/anita-kvamme.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-anita-kvamme-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-annegret-junker-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/annegret-junker.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-annegret-junker-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-arif-wider-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/arif-wider.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-arif-wider-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-baptiste-mace-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/baptiste-mace.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-baptiste-mace-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-barry-oreilly-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/barry-oreilly.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-barry-oreilly-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-beija-nigl-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/beija-nigl.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-beija-nigl-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-chelsea-troy-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/chelsea-troy.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-chelsea-troy-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-chris-richardson-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/chris-richardson.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-chris-richardson-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-chris-simon-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/chris-simon.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-chris-simon-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-clemens-vasters-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/clemens-vasters.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-clemens-vasters-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-cyrille-martraire-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/cyrille-martraire.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-cyrille-martraire-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-david-whitney-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/david-whitney.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-david-whitney-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-davy-landman-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/davy-landman.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-davy-landman-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-dorra-bartaguiz-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/dorra-bartaguiz.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-dorra-bartaguiz-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-dr-jabe-bloom-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/dr-jabe-bloom.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-dr-jabe-bloom-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-dr-simon-harrer-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/dr-simon-harrer.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-dr-simon-harrer-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-emma-houben-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/emma-houben.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-emma-houben-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-eric-evans-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/eric-evans.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-eric-evans-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-fabrice-bernhard-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/fabrice-bernhard.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-fabrice-bernhard-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-ferdinand-ade-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/ferdinand-ade.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-ferdinand-ade-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-gien-verschatse-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/gien-verschatse.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-gien-verschatse-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-gregor-hohpe-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/gregor-hohpe.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-gregor-hohpe-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-helen-rapp-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/helen-rapp.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-helen-rapp-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-henning-schwentner-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/henning-schwentner.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-henning-schwentner-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-ian-cooper-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/ian-cooper.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-ian-cooper-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-jacek-majchrzak-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/jacek-majchrzak.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-jacek-majchrzak-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-jacqui-read-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/jacqui-read.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-jacqui-read-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-james-eastham-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/james-eastham.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-james-eastham-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-james-lewis-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/james-lewis.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-james-lewis-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-jan-van-ryswyck-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/jan-van-ryswyck.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-jan-van-ryswyck-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-jeremy-davis-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/jeremy-davis.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-jeremy-davis-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-joao-rosa-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/joao-rosa.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-joao-rosa-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-jochen-christ-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/jochen-christ.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-jochen-christ-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-john-cutler-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/john-cutler.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-john-cutler-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-john-phamvan-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/john-phamvan.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-john-phamvan-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-jorg-schad-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/jorg-schad.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-jorg-schad-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-josian-chevalier-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/josian-chevalier.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-josian-chevalier-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-juha-pekka-tolvanen-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/juha-pekka-tolvanen.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-juha-pekka-tolvanen-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-julien-topcu-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/julien-topcu.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-julien-topcu-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-karin-hakansson-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/karin-hakansson.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-karin-hakansson-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-karol-skrzymowski-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/karol-skrzymowski.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-karol-skrzymowski-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-kenny-baas-schwegler-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/kenny-baas-schwegler.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-kenny-baas-schwegler-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-kevin-hoffman-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/kevin-hoffman.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-kevin-hoffman-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-kevin-meadows-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/kevin-meadows.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-kevin-meadows-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-kinda-el-maarry-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/kinda-el-maarry.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-kinda-el-maarry-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-kiran-prakash-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/kiran-prakash.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-kiran-prakash-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-krisztina-hirth-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/krisztina-hirth.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-krisztina-hirth-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-kyle-kotowick-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/kyle-kotowick.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-kyle-kotowick-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-laila-bougria-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/laila-bougria.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-laila-bougria-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-leander-vanderbijl-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/leander-vanderbijl.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-leander-vanderbijl-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-lukasz-reszke-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/lukasz-reszke.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-lukasz-reszke-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-marc-klefter-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/marc-klefter.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-marc-klefter-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-mark-richards-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/mark-richards.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-mark-richards-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-martin-gunther-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/martin-gunther.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-martin-gunther-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-maxime-sanglan-charlier-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/maxime-sanglan-charlier.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-maxime-sanglan-charlier-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-michael-plod-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/michael-plod.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-michael-plod-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-mihai-budiu-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/mihai-budiu.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-mihai-budiu-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-mike-wojtyna-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/mike-wojtyna.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-mike-wojtyna-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-mufrid-krilic-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/mufrid-krilic.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-mufrid-krilic-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-natan-silnitsky-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/natan-silnitsky.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-natan-silnitsky-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-neal-ford-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/neal-ford.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-neal-ford-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-omphile-matheolane-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/omphile-matheolane.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-omphile-matheolane-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-oskar-dudycz-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/oskar-dudycz.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-oskar-dudycz-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-pamela-paterson-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/pamela-paterson.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-pamela-paterson-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-paolo-rodeghiero-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/paolo-rodeghiero.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-paolo-rodeghiero-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-paul-rayner-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/paul-rayner.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-paul-rayner-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-philipp-kostyra-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/philipp-kostyra.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-philipp-kostyra-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-pieter-herroelen-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/pieter-herroelen.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-pieter-herroelen-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-prem-chandrasekaran-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/prem-chandrasekaran.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-prem-chandrasekaran-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-rachel-barton-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/rachel-barton.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-rachel-barton-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-ralph-m-debusmann-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/ralph-m-debusmann.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-ralph-m-debusmann-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-rebecca-wirfs-brock-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/rebecca-wirfs-brock.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-rebecca-wirfs-brock-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-ruth-malan-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/ruth-malan.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-ruth-malan-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-sheen-brisals-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/sheen-brisals.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-sheen-brisals-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-simon-brown-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/simon-brown.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-simon-brown-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-sung-shik-jongmans-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/sung-shik-jongmans.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-sung-shik-jongmans-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-thomas-coopman-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/thomas-coopman.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-thomas-coopman-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-thomas-seifert-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/thomas-seifert.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-thomas-seifert-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-tobias-goeschel-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/tobias-goeschel.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-tobias-goeschel-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-trond-hjorteland-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/trond-hjorteland.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-trond-hjorteland-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-woody-zuill-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/woody-zuill.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-woody-zuill-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-xin-yao-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/xin-yao.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-xin-yao-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-younes-zeriahi-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/younes-zeriahi.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-younes-zeriahi-mdx" */)
}

